import JSONAPIAdapter from 'qonto/adapters/base/json-api';
import { paymentLinkV2Namespace } from 'qonto/constants/hosts';

export default class PaymentLinkMethodAdapter extends JSONAPIAdapter {
  namespace = `${paymentLinkV2Namespace}/payment_links`;

  pathForType() {
    return 'methods';
  }
}
