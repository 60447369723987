import { variation } from 'ember-launch-darkly';
import { bool } from 'macro-decorators';

import { ORGA_STATUS } from 'qonto/constants/organization';

import ApplicationAbility from './application';

export default class CashFlowAbility extends ApplicationAbility {
  @bool('pricePlanFeatures.cfmVatCalculation') hasVatCalculationFeature;
  @bool('pricePlanFeatures.cfmCategoryManagement') hasCategoryManagementFeature;
  @bool('pricePlanFeatures.cfmUpcomingTransactionsChart') hasUpcomingTransactionsChartFeature;
  @bool('pricePlanFeatures.cfmUpcomingTransactionsActions') hasUpcomingTransactionsActionsFeature;
  @bool('pricePlanFeatures.cfmForecast') hasForecastFeature;
  @bool('pricePlanFeatures.cfmForecastRestricted') hasForecastRestrictedFeature;

  get canView() {
    // User is a beta tester
    let isBetaUser = this.featuresManager.isEnabled('displayCashflowV1');

    // User is in soft launch
    let isInSoftLaunch = variation('feature--boolean-cash-flow-first-time-experience');

    // If in soft launch, check if user has the forecast restricted feature or full forecast feature
    // (If they have the full feature, they've purchased the add-on and should have access)
    let hasSoftLaunchAccess =
      isInSoftLaunch && (this.hasForecastRestrictedFeature || this.hasForecastFeature);

    let hasFeatureAccess = isBetaUser || hasSoftLaunchAccess;

    return (
      hasFeatureAccess &&
      this.permissions.cash_flow.view &&
      this.organization.status !== ORGA_STATUS.DEACTIVATED
    );
  }

  get canNavigate() {
    return this.canView;
  }

  get canEditForecast() {
    return this.permissions.cash_flow.edit_forecast;
  }

  get canFullyInteractWithForecast() {
    let isBetaUser = this.featuresManager.isEnabled('displayCashflowV1');
    return isBetaUser || (this.hasForecastFeature && this.permissions.cash_flow?.edit_forecast);
  }

  get canViewSidepanelTransactions() {
    return (
      variation('feature--boolean-cash-flow-sidepanel-slice-1') &&
      this.permissions.transactions.read
    );
  }

  get canViewSidepanelForecast() {
    return variation('feature--boolean-cash-flow-sidepanel-slice-2');
  }

  get canViewSidepanel() {
    return this.canViewSidepanelForecast || this.canViewSidepanelTransactions;
  }

  get canViewUpcomingTransactions() {
    return this.permissions.cash_flow.read_upcoming_transactions;
  }

  get canViewUpcomingTransactionsChart() {
    let isBetaUser = this.featuresManager.isEnabled('displayCashflowV1');

    return (
      isBetaUser ||
      (this.hasUpcomingTransactionsActionsFeature &&
        this.permissions.cash_flow?.read_upcoming_transactions)
    );
  }

  get canUseUpcomingTransactionsActions() {
    let isBetaUser = this.featuresManager.isEnabled('displayCashflowV1');

    return (
      isBetaUser ||
      (this.hasUpcomingTransactionsActionsFeature &&
        this.permissions.cash_flow?.manage_upcoming_transactions)
    );
  }

  get canViewForecastV2() {
    return variation('feature--boolean-cash-flow-forecast-v2');
  }

  get canUseFlashForecasts() {
    return variation('feature--boolean-cfm-flash-forecasts');
  }

  get canSeeVatCalculation() {
    let isBetaUser = this.featuresManager.isEnabled('displayCashflowV1');

    return isBetaUser || (this.hasVatCalculationFeature && this.permissions.cash_flow.view);
  }

  get canSeeOverviewCharts() {
    // Hide overview charts for users in soft launch
    let isInSoftLaunch = variation('feature--boolean-cash-flow-first-time-experience');
    return !isInSoftLaunch;
  }
}
