import { service } from '@ember/service';

import ApplicationAbility from './application';

export default class SupplierSubscriptionManagementAbility extends ApplicationAbility {
  @service subscriptionManager;

  get canUse() {
    return (
      this.featuresManager.isEnabled('supplierSubscriptionManagement') &&
      Boolean(this.pricePlanFeatures.supplierSubscriptionManagement) &&
      this.permissions.supplierSubscriptionManagement.access
    );
  }

  get canNavigate() {
    return !this.organization.isDeactivated && (this.canUse || this.canUpsell);
  }

  get canUpsell() {
    return (
      this.featuresManager.isEnabled('supplierSubscriptionManagement') &&
      !this.organization.isItalian &&
      Boolean(this.pricePlanFeatures.bankAccount) &&
      Boolean(!this.pricePlanFeatures.supplierSubscriptionManagement) &&
      this.permissions.subscriptions.update
    );
  }
}
